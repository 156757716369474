<!-- ======= Home Section ======= -->
<section id="home" class="d-flex align-items-center">
    <!--
    <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-9 text-center">
                <h1>Treasure state travel</h1>
                <h2>We are team of talanted nursers</h2>
            </div>
        </div>
        <div class="text-center">
            <a href="#about" class="btn-get-started scrollto">Get Started</a>
        </div>

        <div class="row icon-boxes">
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div class="icon-box">
                    <div class="icon">
                        <fa-icon [icon]="faAnchor"></fa-icon>
                    </div>
                    <h4 class="title"><a href="">Lorem Ipsum</a></h4>
                    <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box">
                    <div class="icon">
                        <fa-icon [icon]="faBook"></fa-icon>
                    </div>
                    <h4 class="title"><a href="">Sed ut perspiciatis</a></h4>
                    <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
                <div class="icon-box">
                    <div class="icon">
                        <fa-icon [icon]="faPaw"></fa-icon>
                    </div>
                    <h4 class="title"><a href="">Magni Dolores</a></h4>
                    <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="500">
                <div class="icon-box">
                    <div class="icon">
                        <fa-icon [icon]="faFingerprint"></fa-icon>
                    </div>
                    <h4 class="title"><a href="">Nemo Enim</a></h4>
                    <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
                </div>
            </div>

        </div>
    </div>
-->
<div class="custom-background">
    <ngb-carousel class="carousel-main">
        <ng-template ngbSlide>
            <div class="my-slide">
                <img src="assets/service-6-min.jpg" alt="Random first slide">
            </div>
            <div class="carousel-caption">
                <h3>We Travel to you...</h3>
                <p>Where ever the road takes us, we will help you care for those who need it.</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="my-slide">
                <img src="assets/service-5-min.jpg" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <h3>We care for you....</h3>
                <p>There's healthcare service and there is genuine care. Treasure State strives for the latter.</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="my-slide">
                <img src="assets/service-4-min.jpg" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <h3>We comfort you...</h3>
                <p>It's not easy having a loved one who needs help doing everyday tasks. That's why we strive to provide
                    comfort before care</p>
            </div>
        </ng-template>
    </ngb-carousel>
</div>


</section>
<!-- End Home -->