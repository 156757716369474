<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

        <div class="section-title">
            <h2>Contact</h2>
            <p>We are always looking for qualified, caring, and dedicated CNA's, LPN's and RN's to join our team. If you
                value caring for those in need and distinguishing yourself as a care taker, we invite you to join the
                Treasure State Family. Please include resume on employment inquiry.
                If you have a staffing need in Montana, we are happy to help. Feel free to contact us below</p>
        </div>

        <div>
            <!-- <iframe style="border:0; width: 100%; height: 270px;"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"

                frameborder="0" allowfullscreen></iframe> -->
            <div class="mapouter">
                <div class="gmap_canvas"><iframe width="1000" height="270" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=1118%20Minuteman%20St%2C%20Billings%20Mt%2059105&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
                        href="https://google-map-generator.com">google map generator</a></div>
                <style>
                    .mapouter {
                        position: relative;
                        text-align: right;
                        height: 270px;
                        width: 100%;
                    }

                    .gmap_canvas {
                        overflow: hidden;
                        background: none !important;
                        height: 270px;
                        width: 1000px;
                    }
                </style>
            </div>
        </div>

        <div class="row mt-5">

            <div class="col-lg-4">
                <div class="info">
                    <div class="address">
                        <fa-icon [icon]="faMapMarkedAlt"></fa-icon>

                        <h4>Location:</h4>
                        <p>1118 Minuteman St, Billings Mt 59105</p>

                    </div>

                    <div class="email">
                        <fa-icon [icon]="faEnvelope"></fa-icon>

                        <h4>Email:</h4>
                        <p>treasurestatetravel@gmail.com</p>

                    </div>

                    <div class="phone">
                        <fa-icon [icon]="faPhone"></fa-icon>

                        <h4>Call:</h4>
                        <p>+1 (406) 853-5208</p>

                    </div>

                </div>

            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">

                <form #form action="http://formspree.io/treasurestatetravel@gmail.com" method="post" role="form"
                    class="contact-us-form" encType="multipart/form-data">

                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                                data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                            <div class="validate"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email"
                                data-rule="email" data-msg="Please enter a valid email" />
                            <div class="validate"></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"
                            data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div class="validate"></div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" name="message" rows="5" data-rule="required"
                            data-msg="Please write something for us" placeholder="Message"></textarea>
                        <div class="validate"></div>
                    </div>

                    <button class="btn btn-primary" type="submit" id="sendMessage" (click)="form.submit()">Send
                        Message</button>
                </form>


            </div>

        </div>

    </div>
</section>
