<section id="about" class="about">
    <div class="container" data-aos="fade-up">

        <div class="section-title">
            <h2>About Us</h2>
            <p>Our clients know and trust our team to ensure their residence are cared for and comforted. </p>
        </div>

        <div class="row content">
            <div class="col-lg-12">

                <p>
                    Treasure State Travel has always been dedicated first and foremost to providing superior care from
                    qualified,
                    professional, and most of all caring workers from the Treasure State Travel team. When considering
                    Treasure State for your caring needs,
                    you can rest assured that priority care is given that cannot be matched.
                </p>

                <!-- <ul>
                    <li>
                        <fa-icon [icon]="faCheck"></fa-icon> Caring Workers
                    </li>
                    <li>
                        <fa-icon [icon]="faCheck"></fa-icon> Prefessionalism
                    </li>
                    <li>
                        <fa-icon [icon]="faCheck"></fa-icon> Caring workers
                    </li>
                </ul> -->
            </div>
            <div class="col-lg-12 about-us-content disp-block" style="display: flex;">
                <div style="display: block; " class="col-lg-9 col-12 col-sm-12">
                    <p>
                        Treasure State Travel was started by the same people who created offered care to our clients.
                        Jerri Hrubes (Founder & CEO) began as a CNA who traveled across the rural plains of Montana to
                        provide care for those who needed it.
                        Jerri is an infectious soul, who gave everything she could to make those she served most
                        comfortable around her.
                        Through years of caring service, she felt that she could do more to help the people she worked
                        with as well as the people she cared for.
                        That day, the Treasure State family was born. <br>
                    </p>
                    <div>
                        <p style="border-top: 4px solid #64baee;width: 75%;">

                            <strong>Jerri Hrubes - CEO</strong>

                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-3 col-sm-12">
                    <img src="assets/CEO.jpg" width="200px" height="200px"
                        style="border-radius: 50%;width: 200px; height: 200px;" />
                </div>
            </div>
            <!-- <div class="col-lg-4 p-0">
                
            </div> -->

        </div>

    </div>
</section>

<!-- ======= Counts Section ======= -->
<section id="counts" class="counts section-bg">
    <div class="container">

        <div class="row justify-content-end">

            <div class="col-lg-4 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                    <span data-toggle="counter-up">12</span>
                    <p>Employees</p>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                    <span data-toggle="counter-up">85</span>
                    <p>Projects</p>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                    <span data-toggle="counter-up">15</span>
                    <p>Years of experience</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                    <span data-toggle="counter-up">8</span>
                    <p>Facilities</p>
                </div>
            </div>

        </div>

    </div>
</section>
<!-- End Counts Section -->